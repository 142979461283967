import {
    TInput,
    TButton,
    TSelect,
    TCheckbox,
    TToggle
    // ...Rest of the components
} from 'vue-tailwind/dist/components';

export default {
    't-input': {
        component: TInput,
        props: {
            classes: 'block w-full px-3 py-2 transition duration-100 ease-in-out border border-gray-300 rounded shadow-sm focus:ring-2 focus:ring-blue-500 focus:outline-none focus:ring-opacity-50 placeholder-gray-300 disabled:opacity-50 disabled:cursor-not-allowed',
            variants: {
                error: 'block w-full rounded border-red-300 bg-red-50 placeholder-red-200 text-red-900',
                success: 'block w-full rounded border-green-300 bg-green-50 placeholder-gray-400 text-green-900'
            }
        }
    },
    't-select': {
        component: TSelect,
        props: {
            // classes: 'border-2 block w-full rounded text-gray-800'
            variants: {
                error: 'block w-full rounded border-red-300 bg-red-50 placeholder-red-200 text-red-900',
                success: 'block w-full rounded border-green-300 bg-green-50 placeholder-gray-400 text-green-900'
            }
        }
    },
    't-button': {
        component: TButton,
        props: {
            fixedClasses: 'font-semibold rounded transition duration-100 ease-in-out',
            classes: 'block px-4 py-2 text-white transition duration-100 ease-in-out bg-indigo-600 border border-transparent rounded shadow-sm hover:bg-indigo-700 focus:border-blue-500 focus:ring-2 focus:ring-blue-500 focus:outline-none focus:ring-opacity-50  disabled:opacity-50 disabled:cursor-not-allowed',
            variants: {
                secondary: 'block px-4 py-2 text-gray-800 bg-white border border-gray-300 hover:bg-gray-50 focus:border-blue-500 focus:ring-2 focus:ring-blue-500 focus:outline-none focus:ring-opacity-50 disabled:opacity-50 disabled:cursor-not-allowed',
                error: 'text-white bg-red-500 border border-transparent shadow-sm hover:bg-red-600 focus:border-blue-500 focus:ring-2 focus:ring-blue-500 focus:outline-none focus:ring-opacity-50  disabled:opacity-50 disabled:cursor-not-allowed',
                success: 'text-white bg-green-500 border border-transparent shadow-sm hover:bg-green-600 focus:border-blue-500 focus:ring-2 focus:ring-blue-500 focus:outline-none focus:ring-opacity-50  disabled:opacity-50 disabled:cursor-not-allowed',
                link: 'text-blue-500 underline hover:text-blue-600 focus:border-blue-500 focus:ring-2 focus:ring-blue-500 focus:outline-none focus:ring-opacity-50  disabled:opacity-50 disabled:cursor-not-allowed',
                outline: 'text-white px-4 py-2 bg-none border border-white border-opacity-50 hover:border-opacity-100 hover:bg-white hover:bg-opacity-10 rounded focus:border-blue-500 focus:ring-2 focus:ring-blue-500 focus:outline-none focus:ring-opacity-50 disabled:opacity-50 disabled:cursor-not-allowed'
            }
        }
    },
    't-checkbox': {
        component: TCheckbox,
    },
    't-toggle': {
        component: TToggle,
        props: {
            fixedClasses: {
                wrapper: 'relative inline-flex flex-shrink-0 cursor-pointer transition-colors ease-in-out duration-200',
                wrapperChecked: 'relative inline-flex flex-shrink-0 cursor-pointer transition-colors ease-in-out duration-200  border-2 border-transparent focus:ring-2 focus:ring-blue-500 focus:outline-none focus:ring-opacity-50',
                wrapperDisabled: 'relative inline-flex flex-shrink-0 cursor-pointer transition-colors ease-in-out duration-200 opacity-50 cursor-not-allowed',
                wrapperCheckedDisabled: 'relative inline-flex flex-shrink-0 cursor-pointer transition-colors ease-in-out duration-200 opacity-50 cursor-not-allowed border-2 border-transparent focus:ring-2 focus:ring-blue-500 focus:outline-none focus:ring-opacity-50',
                button: 'inline-block absolute transform translate-x-0 transition ease-in-out duration-200',
                buttonChecked: 'inline-block absolute transform translate-x-full transition ease-in-out duration-200',
                checkedPlaceholder: 'inline-block',
                uncheckedPlaceholder: 'inline-block'
            },
            classes: {
                wrapper: 'bg-gray-200 rounded-full border-2 border-transparent focus:ring-2 focus:ring-blue-500 focus:outline-none focus:ring-opacity-50',
                wrapperChecked: 'bg-blue-500 rounded-full',
                wrapperDisabled: 'bg-gray-100 rounded-full border-2 border-transparent focus:ring-2 focus:ring-blue-500 focus:outline-none focus:ring-opacity-50',
                wrapperCheckedDisabled: 'bg-blue-500',
                button: 'h-5 w-5 rounded-full bg-white shadow flex items-center justify-center text-gray-400 text-xs',
                buttonChecked: 'h-5 w-5 rounded-full bg-white shadow flex items-center justify-center text-blue-500 text-xs',
                checkedPlaceholder: 'rounded-full w-5 h-5 flex items-center justify-center text-gray-400 text-xs',
                uncheckedPlaceholder: 'rounded-full w-5 h-5 flex items-center justify-center text-gray-400 text-xs'
            },
            variants: {
                danger: {
                    wrapperChecked: 'bg-red-500 rounded-full',
                    wrapperCheckedDisabled: 'bg-red-500 rounded-full'
                },
                success: {
                    wrapperChecked: 'bg-green-500 rounded-full',
                    wrapperCheckedDisabled: 'bg-green-500 rounded-full'
                },
                box: {
                    wrapper: 'bg-gray-100 rounded-sm border-2 border-transparent focus:ring-2 focus:ring-blue-500 focus:outline-none focus:ring-opacity-50',
                    wrapperChecked: 'bg-blue-500 rounded-sm',
                    wrapperCheckedDisabled: 'bg-blue-500 rounded-sm',
                    button: 'h-6 w-6 rounded-sm bg-white shadow flex items-center justify-center text-gray-400 text-xs',
                    buttonChecked: 'h-6 w-6 rounded-sm  bg-white shadow flex items-center justify-center text-blue-500 text-xs',
                    checkedPlaceholder: 'rounded-sm w-6 h-6 flex items-center justify-center text-gray-400 text-xs',
                    uncheckedPlaceholder: 'rounded-sm w-6 h-6 flex items-center justify-center text-gray-400 text-xs'
                }
            }
        }
        // ...Rest of the components
    }
}