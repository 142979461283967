import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

import mutations from "./mutations";
import actions from "./actions";
import getters from "./getters";

import { baseFields } from "./baseData"

export default new Vuex.Store({
    state: {
        preferences: {},
        preferencesLoaded: false,
        newRegistration: true,
        registrationStarted: false,
        fields: JSON.parse(JSON.stringify(baseFields)),
        kids: [],
        originalKidIDs: []
    },
    getters,
    mutations,
    actions,
    modules: {}
})