import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import * as Sentry from "@sentry/vue";
import { Integrations } from "@sentry/tracing";


import "tailwindcss/tailwind.css"
import '@/assets/styles/main.styl'

import VueTailwind from 'vue-tailwind'
import Config from '../components.config.js';

import VueTippy from 'vue-tippy'

import Cleave from 'cleave.js';
require('cleave.js/dist/addons/cleave-phone.us');

import { library } from '@fortawesome/fontawesome-svg-core'
import { faUser, faPlusCircle, faSpinner, faBadgeCheck, faInfoCircle } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

Sentry.init({
    Vue,
    dsn: "https://71f36461fb2e456dabd6b962b47f7602@o538668.ingest.sentry.io/5657037",
    integrations: [new Integrations.BrowserTracing()],

    // We recommend adjusting this value in production, or using tracesSampler
    // for finer control
    tracesSampleRate: 1.0,
});

library.add(faUser, faPlusCircle, faSpinner, faBadgeCheck, faInfoCircle)
Vue.component('font-awesome-icon', FontAwesomeIcon)

Vue.config.productionTip = false


Vue.use(VueTailwind, Config)
Vue.use(VueTippy, {
    arrow: true,
    animation: 'fade'
})

Vue.directive('cleave', {
    inserted: (el, binding) => {
        el.cleave = new Cleave(el, binding.value || {})
    },
    update: (el) => {
        const event = new Event('input', { bubbles: true });
        setTimeout(function () {
            el.value = el.cleave.properties.result
            el.dispatchEvent(event)
        }, 100);
    }
})

new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app')