import { baseFields } from "./baseData";

export default {
  CLEAR_FIELDS(state) {
    state.fields = JSON.parse(JSON.stringify(baseFields));
  },

  UPDATE_FIELDS(state, updatedProps) {
    // merge passed properties in to existing object
    Object.assign(state.fields, updatedProps);
  },

  SET_AIRTABLE_ID(state, id) {
    state.fields.airtableID = id;
  },

  UPDATE_FIELD_VALUE(state, { field, newValue }) {
    state.fields[field].value = newValue;
  },

  UPDATE_FIELD_ERROR(state, { field, errored }) {
    state.fields[field].error = errored;
  },

  SET_NEW_REGISTRATION(state, value) {
    state.newRegistration = value;
  },

  SET_REGISTRATION_STARTED(state, value) {
    state.registrationStarted = value;
  },

  SET_REGISTRATION_STATUS(state, value) {
    state.fields.registrationStatus = value;
  },

  SET_PAYMENT_STATUS(state, value) {
    state.fields.paymentStatus = value;
  },

  ADD_KID(state, kid) {
    state.kids.push(kid);
  },

  UPDATE_KID(state, { airtableID, updatedProps }) {
    const index = state.kids.findIndex((kid) => kid.airtableID === airtableID);

    // merge passed properties in to existing object
    Object.assign(state.kids[index], updatedProps);
  },

  SET_ORIGINAL_KID_IDS(state, value) {
    if (value) {
      state.originalKidIDs = value;
    }
  },

  UPDATE_KID_FIELD_VALUE(state, { id, field, newValue }) {
    state.kids[id][field].value = newValue;
  },

  UPDATE_KID_FIELD_ERROR(state, { id, field, errored }) {
    state.kids[id][field].error = errored;
  },

  REMOVE_KID(state, id) {
    state.kids.splice(id, 1);
  },

  CLEAR_KIDS(state) {
    state.kids = [];
  },

  SET_PREFERENCE(state, { key, value }) {
    let localValue = value;

    if (value === 'true') {
        localValue = true;
    } else if (value === 'false') {
        localValue = false;
    }

    state.preferences[key] = localValue;
  },

  SET_PREFERENCES_LOADED(state) {
    state.preferencesLoaded = true;
  }
};
