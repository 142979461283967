<template>
  <div>
    <!-- This example requires Tailwind CSS v2.0+ -->
    <div class="relative bg-white overflow-hidden">
      <div class="max-w-7xl mx-auto">
        <div
          class="relative z-10 pb-8 bg-white sm:pb-16 md:pb-20 lg:max-w-2xl lg:w-full lg:pb-28 xl:pb-32"
        >
          <svg
            class="hidden lg:block absolute right-0 inset-y-0 h-full w-48 text-white transform translate-x-1/2"
            fill="currentColor"
            viewBox="0 0 100 100"
            preserveAspectRatio="none"
            aria-hidden="true"
          >
            <polygon points="50,0 100,0 50,100 0,100" />
          </svg>
          <main
            class="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 pt-16 md:pt-32 pb-10"
          >
            <div
              class="sm:text-center lg:text-left sm:flex-col sm:flex sm:items-center lg:block"
            >
              <img class="w-32 opacity-25" src="@/assets/logo.png" alt="" />
              <h1
                class="text-4xl mt-10 tracking-tight font-extrabold text-gray-900 sm:text-5xl md:text-6xl"
              >
                <span class="block text-indigo-600 xl:inline"
                  >Guthrie Soccer</span
                >
              </h1>
              <p
                class="mt-3 text-base text-gray-500 sm:mt-5 sm:text-lg sm:max-w-md sm:mx-auto md:mt-5 md:text-xl lg:mx-0"
              >
                Soccer Fundamentals. Soccer Fun.
              </p>
              <div
                class="mt-5 sm:mt-8 sm:flex sm:justify-center lg:justify-start"
              >
                <span class="hidden">{{ preferencesLoaded }}</span>
                <t-button v-if="preferences.enable_registration" to="/register"
                  >Register for the 2024 Fall Clinic</t-button
                >
                <t-button
                  to="/register"
                  v-if="!preferences.enable_registration"
                  disabled
                  >Registration Closed</t-button
                >
              </div>
            </div>
            <div
              class="mt-4 text-left sm:text-center lg:text-left"
              v-if="!preferences.enable_registration"
            >
              <p>
                <a href="mailto:guthrie.soccer@gmail.com">Email us</a> to see if
                any spots are still open.
              </p>
            </div>
          </main>
        </div>
      </div>
      <div class="lg:absolute lg:inset-y-0 lg:right-0 lg:w-1/2">
        <img
          class="h-56 w-full object-cover sm:h-72 md:h-96 lg:w-full lg:h-full"
          src="@/assets/bg.jpg"
          alt=""
        />
        <div
          class="bg-black opacity-40 absolute w-full h-full top-0 left-0"
        ></div>
      </div>
    </div>

    <div class="pb-24 pt-16 bg-gray-50">
      <div class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div class="lg:text-center">
          <h2
            class="text-base text-indigo-600 font-semibold tracking-wide uppercase"
          >
            About Guthrie Soccer
          </h2>
          <p
            class="mt-2 text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl"
          >
            Low Pressure Soccer
          </p>
          <p class="mt-4 max-w-2xl text-xl text-gray-500 lg:mx-auto">
            We provide soccer training for your kids that is fun and stress free
            (though we do still keep score).
          </p>
        </div>

        <div class="mt-10">
          <dl
            class="space-y-10 md:space-y-0 md:grid md:grid-cols-2 md:gap-x-8 md:gap-y-10"
          >
            <div class="flex">
              <div class="flex-shrink-0">
                <div
                  class="flex items-center justify-center h-12 w-12 rounded-md bg-indigo-500 text-white"
                >
                  <!-- Heroicon name: outline/globe-alt -->
                  <svg
                    class="h-6 w-6"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M21 12a9 9 0 01-9 9m9-9a9 9 0 00-9-9m9 9H3m9 9a9 9 0 01-9-9m9 9c1.657 0 3-4.03 3-9s-1.343-9-3-9m0 18c-1.657 0-3-4.03-3-9s1.343-9 3-9m-9 9a9 9 0 019-9"
                    />
                  </svg>
                </div>
              </div>
              <div class="ml-4">
                <dt class="text-lg leading-6 font-medium text-gray-900">
                  Fundamental Soccer Skills
                </dt>
                <dd class="mt-2 text-base text-gray-500">
                  At each session, we teach fundamental soccer skills and have
                  the players participate in small-sided soccer games (anywhere
                  from 4 players vs. 4 players to 7 vs. 7).
                </dd>
              </div>
            </div>

            <div class="flex">
              <div class="flex-shrink-0">
                <div
                  class="flex items-center justify-center h-12 w-12 rounded-md bg-indigo-500 text-white"
                >
                  <!-- Heroicon name: outline/scale -->
                  <svg
                    class="h-6 w-6"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M3 6l3 1m0 0l-3 9a5.002 5.002 0 006.001 0M6 7l3 9M6 7l6-2m6 2l3-1m-3 1l-3 9a5.002 5.002 0 006.001 0M18 7l3 9m-3-9l-6-2m0-2v2m0 16V5m0 16H9m3 0h3"
                    />
                  </svg>
                </div>
              </div>
              <div class="ml-4">
                <dt class="text-lg leading-6 font-medium text-gray-900">
                  Stress Free
                </dt>
                <dd class="mt-2 text-base text-gray-500">
                  We keep score, but we do not keep team win/loss records or
                  statistics. Both teams get snacks after the game :)
                </dd>
              </div>
            </div>

            <div class="flex">
              <div class="flex-shrink-0">
                <div
                  class="flex items-center justify-center h-12 w-12 rounded-md bg-indigo-500 text-white"
                >
                  <!-- Heroicon name: outline/lightning-bolt -->
                  <svg
                    class="h-6 w-6"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M13 10V3L4 14h7v7l9-11h-7z"
                    />
                  </svg>
                </div>
              </div>
              <div class="ml-4">
                <dt class="text-lg leading-6 font-medium text-gray-900">
                  Affordable Registration Fees
                </dt>
                <dd class="mt-2 text-base text-gray-500">
                  $65 per child ($5 discount per additional child if registered
                  before March 31st)
                </dd>
              </div>
            </div>

            <div class="flex">
              <div class="flex-shrink-0">
                <div
                  class="flex items-center justify-center h-12 w-12 rounded-md bg-indigo-500 text-white"
                >
                  <!-- Heroicon name: outline/annotation -->
                  <svg
                    class="h-6 w-6"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M7 8h10M7 12h4m1 8l-4-4H5a2 2 0 01-2-2V6a2 2 0 012-2h14a2 2 0 012 2v8a2 2 0 01-2 2h-3l-4 4z"
                    />
                  </svg>
                </div>
              </div>
              <div class="ml-4">
                <dt class="text-lg leading-6 font-medium text-gray-900">
                  Guaranteed Fun
                </dt>
                <dd class="mt-2 text-base text-gray-500">
                  We value having fun while learning and trying our best.
                </dd>
              </div>
            </div>
          </dl>
        </div>
      </div>
    </div>

    <div class="bg-white">
      <div
        class="max-w-7xl mx-auto py-16 px-4 sm:px-6 lg:py-24 lg:px-8 lg:flex lg:items-center lg:justify-between"
      >
        <h2
          class="text-3xl font-extrabold tracking-tight text-gray-900 sm:text-4xl"
        >
          <span class="block text-2xl">Interested?</span>
          <span class="block text-indigo-600 mt-2"
            >Register for the 2024 Fall Clinic today.</span
          >
        </h2>
        <div>
          <t-button
            class="mt-10"
            v-if="preferences.enable_registration"
            to="/register"
            >Register Now</t-button
          >
          <t-button
            class="mt-10"
            v-if="!preferences.enable_registration"
            to="/register"
            disabled
            >Registration Closed</t-button
          >
        </div>
      </div>
    </div>

    <div class="home container mx-auto py-16 px-4" style="max-width: 955px">
      <div class="lg:text-center">
        <h2
          class="text-base text-indigo-600 font-semibold tracking-wide uppercase"
        >
          More Info
        </h2>
        <p
          class="mt-2 text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl"
        >
          Frequently Asked Questions
        </p>
      </div>

      <h3 class="mt-16">How much does Guthrie Soccer cost?</h3>

      <p>
        The standard registration fee is ${{ price }} per child. However,
        families who register by {{ lastFamilyDiscountDate }} at midnight and
        who have more than one child will get an automatic discount at checkout.
        [2nd child in same family = ${{ price - discount * 1 }}, 3rd child = ${{
          price - discount * 2
        }}, 4th child = ${{ price - discount * 3 }}, 5th child (or more) = ${{
          price - discount * 4
        }}. For example, a family that registers 4 children will get charged ${{
          price
        }}
        + ${{ price - discount * 1 }} + ${{ price - discount * 2 }} + ${{
          price - discount * 3
        }}
        = ${{
          price +
          (price - discount * 1) +
          (price - discount * 2) +
          (price - discount * 3)
        }}
        instead of ${{ price * 4 }}.] You will need a debit or credit card to
        complete registration, and once you’ve paid, you’ll receive an email
        confirmation.
      </p>

      <h3>What are the age requirements?</h3>

      <p>
        Guthrie Soccer is for children who are 5-12 years old. As a general
        rule, 4-year-old players are not "ready" to play; however, children who
        are too young as of {{ ageDate }} might still be allowed to play if they
        are turning 5 very soon after {{ ageDate }}. In a similar way, players
        who have their 12th birthday just before {{ ageDate }} might be allowed
        to play. Email
        <a href="mailto:guthrie.soccer@gmail.com">guthrie.soccer@gmail.com</a>
        before registering to present your best arguments. :)
      </p>

      <h3>How long is the season?</h3>

      <p>
        The fall season starts on {{ startDate }}, and continues until
        {{ endDate }}.
      </p>

      <h3>Where are games played?</h3>

      <p>
        Field location will be finalized and announced soon.
        All practices/games are on the fields in front of Suber Road Baptist
        Church.
        <a
          href="https://goo.gl/maps/fSNtux2VFZom1mMT6"
          target="_blank"
          rel="noopener"
          >Directions</a
        >
      </p>

      <h3>When are the games played?</h3>

      <p>
        Your child's team will play two nights each week on Monday, Tuesday,
        and/or Thursday. Each practice/game is one hour long and is scheduled to
        begin at 5:30 p.m., 6:30 p.m., or 7:30 p.m. For each session, we will
        teach the players some basic soccer skills for 15-20 minutes and then
        play games for the remaining portion of the hour. You will receive the
        detailed schedule for the entire season about one week before the first
        night of Guthrie Soccer.
      </p>

      <h3>What are the age groups?</h3>

      <p>
        We divide players into three age groups for boys and three age groups
        for girls (based on the child’s age as of May 1): Younger boys/girls,
        ages 5-6, Middle boys/girls, ages 7-8, Older boys/girls, ages 9-11.
        After dividing the players into age groups, we assign players to teams
        based on prior experience in Guthrie Soccer or other soccer leagues.
      </p>

      <h3>Can I request teammates for my child?</h3>

      <p>
        Siblings in the same age group are AUTOMATICALLY placed on same team. We
        can place cousins together, but you have to let us know (they will not
        automatically be placed on the same team). If you don’t register
        siblings in the same age category, you may enter one teammate request
        per child. The teammate you request should also request your child, and
        the requested teammate must be same age group as your child. We will do
        our best to grant all teammate requests.
      </p>

      <h3>Do you donate any proceeds to charity?</h3>

      <p>
        Guthrie Soccer will be donating 20% of this season’s net proceeds to His
        Turn Soccer, a non-profit organization that uses soccer in inner-city
        schools to introduce the gospel to unchurched children. To find out more
        about His Turn, or to donate to the organization, navigate to
        <a target="_blank" rel="noopener" href="https://histurnsoccer.org"
          >histurnsoccer.org</a
        >
        or find them on
        <a
          target="_blank"
          rel="noopener"
          href="https://www.facebook.com/histurnsoccer"
          >Facebook</a
        >. Full disclosure: Guthrie Soccer’s own Coach Bailey is the executive
        director of His Turn Soccer, but she did not twist any arms to get this
        free advertising.
      </p>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import { mapState } from "vuex";

export default {
  name: "Home",
  computed: {
    ...mapState(["preferences", "preferencesLoaded"]),
  },
  data() {
    console.log(this.preferences);
    return {
      price: 65,
      discount: 5,
      startDate: "October 1st",
      ageDate: "September 1st",
      endDate: "October 10th",
      lastFamilyDiscountDate: "September 23rd",
    };
  },
};
</script>
