<template>
  <div id="app">
    <router-view/>
  </div>
</template>

<script>
export default {
  name: "App",
  async mounted() {
    // load preferences
    await this.$store.dispatch("loadPreferences");
  }
}
</script>

