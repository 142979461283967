import Airtable from "airtable";
const base = new Airtable({ apiKey: "patoTm7XJS3z3B9kb.d6a8954f9b42ee421609512a137d8c5924bc51122c510883bae54f6d23e0c7e5" }).base(
  "appcJIEiDAj4bYz1T"
);

import { baseFields } from "./baseData";

export default {
  // loads a Household from Airtable given airtableID
  loadHousehold({ commit, dispatch }, airtableID) { //eslint-disable-line
    return new Promise((resolve, reject) => {
      // get household from airtable
      base("Households").find(airtableID, async (err, record) => {
        if (err) {
          reject(err);
        }

        // make sure fields are set to blank
        commit("CLEAR_FIELDS");

        let updatedFields = JSON.parse(JSON.stringify(baseFields));

        updatedFields.airtableID = record.getId();
        updatedFields.householdLastName.value = record.get(
          "Household Last Name"
        );
        updatedFields.registrationStatus.value = record.get(
          "Registration Status"
        );
        updatedFields.parent1Name.value = record.get("Parent 1 Name");
        updatedFields.parent2Name.value = record.get("Parent 2 Name");
        updatedFields.phoneNumber.value = record.get("Phone Number");
        updatedFields.email.value = record.get("Email");
        updatedFields.address1.value = record.get("Address Line 1");
        updatedFields.address2.value = record.get("Address Line 2");
        updatedFields.addressCity.value = record.get("City");
        updatedFields.addressState.value = record.get("State");
        updatedFields.addressZip.value = record.get("Zip");
        updatedFields.medicalCarrier.value = record.get("Medical: Carrier");
        updatedFields.medicalMemberID.value = record.get("Medical: Member ID");
        updatedFields.medicalGroupID.value = record.get("Medical: Group ID");

        commit("UPDATE_FIELDS", updatedFields);

        const kidIDs = record.get("Kids");
        commit("CLEAR_KIDS");

        // load all kids
        if (kidIDs) {
          await Promise.all(
            kidIDs.map(async (id) => await dispatch("loadKid", id))
          );
        }

        // cache original kid ids
        commit("SET_ORIGINAL_KID_IDS", kidIDs);

        resolve(true);
      });
    });
  },

  // load preferences from Airtable
  loadPreferences({ commit }) { //eslint-disable-line
    fetch("/api/config").then(async (value) => {
      const prefs = await value.json();
      Object.keys(prefs).forEach((key) => {
        commit("SET_PREFERENCE", {
          key: key,
          value: prefs[key],
        });
      });
      commit("SET_PREFERENCES_LOADED", true);
    });
  },

  // sync current household data to airtable
  async syncToAirtable({ state, commit, dispatch }) {
    const recordFields = {
      "Phone Number": state.fields.phoneNumber.value,
      "Household Last Name": state.fields.householdLastName.value,
      "Payment Status": state.fields.paymentStatus.value,
      "Parent 1 Name": state.fields.parent1Name.value,
      "Parent 2 Name": state.fields.parent2Name.value,
      Email: state.fields.email.value,
      "Address Line 1": state.fields.address1.value,
      "Address Line 2": state.fields.address2.value,
      City: state.fields.addressCity.value,
      State: state.fields.addressState.value,
      Zip: state.fields.addressZip.value,
      "Medical: Carrier": state.fields.medicalCarrier.value,
      "Medical: Member ID": state.fields.medicalMemberID.value,
      "Medical: Group ID": state.fields.medicalGroupID.value,
    };

    let airtableID;

    try {
      airtableID = await syncFields({
        baseName: "Households",
        fields: recordFields,
        airtableID: state.fields.airtableID,
      });
      await dispatch("syncRegistrationStatus", {
        status: "Abandoned Registration",
        id: state.fields.airtableID,
      });
    } catch (err) {
      throw Error(err);
    }

    let kidIDs = [];

    // update or create kids records in airtable
    try {
      await Promise.all(
        state.kids.map(async (kid) => {
          //eslint-disable-line
          const kidFields = {
            Name: kid.name.value,
            "Birth Date": kid.birthDate.value,
            Grade: kid.grade.value,
            "Prefer Play Up": kid.preferPlayUp.value,
            Experience:
              kid.soccerExperience.value === ""
                ? null
                : kid.soccerExperience.value,
            Gender: kid.gender.value === "" ? null : kid.gender.value,
            Guthrie:
              kid.guthrieSeasons.value === "" ? null : kid.guthrieSeasons.value,
            School: kid.school.value === "" ? null : kid.school.value,
            "Avoid Day": kid.avoidDay.value === "" ? null : kid.avoidDay.value,
            "School Other": kid.schoolOther.value,
            "Shirt Size":
              kid.shirtSize.value === "" ? null : kid.shirtSize.value,
            "Allergies/Medical Notes": kid.allergiesMedicalNotes.value,
            "Preferred Teammate(s)": kid.preferredTeammates.value,
            Household: [airtableID],
          };

          let kidID = await syncFields({
            baseName: "Kids",
            fields: kidFields,
            airtableID: kid.airtableID,
          });
          kidIDs.push(kidID);
        })
      );
    } catch (err) {
      throw Error(err);
    }

    // compare updated kids to original kid ids
    if (state.originalKidIDs) {
      const deleteIDs = state.originalKidIDs.filter(
        (kid) => !kidIDs.includes(kid)
      );

      // delete kids that are not being registered from airtable
      deleteIDs.map((id) => dispatch("deleteKid", id));
    }

    commit("SET_AIRTABLE_ID", airtableID);

    // return record id
    return airtableID;
  },

  syncRegistrationStatus({ commit }, { status, id }) {
    const validStatuses = [
      "Unregistered",
      "Registration Complete",
      "Abandoned Registration",
    ];

    return new Promise((resolve, reject) => {
      if (validStatuses.includes(status)) {
        // update airtable record
        base("Households").update(
          [
            {
              id: id,
              fields: {
                "Registration Status": status,
              },
            },
          ],
          (err) => {
            if (err) {
              new Error("Could not update registration status");
            }

            commit("SET_REGISTRATION_STATUS", status);
            resolve(status);
            return;
          }
        );
      } else {
        reject(
          Error(
            `Registration status must be one of these options: ${validStatuses}`
          )
        );
      }
    });
  },

  async syncPaymentStatus({ commit }, { status, id, amount }) {
    const validStatuses = ["Unpaid", "Paid"];

    if (validStatuses.includes(status)) {
      // update airtable record
      base("Households").update(
        [
          {
            id: id,
            fields: {
              "Payment Status": status,
              "Payment Amount": amount,
            },
          },
        ],
        (err) => {
          if (err) {
            new Error("Could not update payment status");
          }

          commit("SET_PAYMENT_STATUS", status);
          return;
        }
      );
    } else {
      throw Error(
        `Payment status must be one of these options: ${validStatuses}`
      );
    }
  },

  async syncPromoCodeStatus({ commit }, { householdID, promoCodeID }) { //eslint-disable-line
    // update airtable record
    base("Households").update(
      [
        {
          id: householdID,
          fields: {
            "Promo Codes": [promoCodeID],
          },
        },
      ],
      (err) => {
        if (err) {
          new Error("Could not update promo code");
        }
        return;
      }
    );
  },

  updateHouseholdField({ state, commit }, { field, newValue }) {
    const validation = state.fields[field].validation;
    const required = state.fields[field].required;
    let errored = validateField({ required, validation, value: newValue });

    commit("UPDATE_FIELD_VALUE", { field, newValue });
    commit("UPDATE_FIELD_ERROR", { field, errored });
  },

  validateFields({ state, commit }) {
    return new Promise((resolve) => {
      var errored = false;

      for (let field of Object.keys(state.fields)) {
        if (
          typeof state.fields[field] == "object" &&
          state.fields[field] !== null
        ) {
          const error = validateField(state.fields[field]);
          error ? (errored = true) : null;
          commit("UPDATE_FIELD_ERROR", { field: field, errored: error });
        }
      }

      resolve(errored);
    });
  },

  validateKids({ state, commit }) {
    return new Promise((resolve) => {
      var errored = false;

      for (let [index, kid] of state.kids.entries()) {
        for (let field of Object.keys(kid)) {
          if (typeof kid[field] == "object" && kid[field] !== null) {
            const error = validateField(kid[field]);
            error ? (errored = true) : null;
            commit("UPDATE_KID_FIELD_ERROR", {
              id: index,
              field: field,
              errored: error,
            });
          }
        }
      }
      resolve(errored);
    });
  },

  updateKidField({ state, commit }, { id, field, newValue }) {
    const validation = state.kids[id][field].validation;
    const required = state.kids[id][field].required;
    const greaterThan = state.kids[id][field].greaterThan;
    let errored = validateField({
      required,
      validation,
      greaterThan,
      value: newValue,
    });

    commit("UPDATE_KID_FIELD_VALUE", { id, field, newValue });
    commit("UPDATE_KID_FIELD_ERROR", { id, field, errored });
  },

  addKid({ commit }) {
    const newKid = {
      airtableID: null,
      name: {
        value: "",
        required: true,
        error: false,
      },
      birthDate: {
        value: "",
        validation: "DATE",
        greaterThan: "10/01/2019",
        required: true,
        error: false,
      },
      grade: {
        value: "",
        required: true,
        error: false,
      },
      preferPlayUp: {
        value: false,
        error: false,
      },
      soccerExperience: {
        value: "",
        required: true,
        error: false,
      },
      gender: {
        value: "",
        required: true,
        error: false,
      },
      guthrieSeasons: {
        value: "",
        required: true,
        error: false,
      },
      school: {
        value: "",
        required: true,
        error: false,
      },
      schoolOther: {
        value: "",
        error: false,
      },
      shirtSize: {
        value: "YS",
        required: true,
        error: false,
      },
      avoidDay: {
        value: "",
        error: false,
      },
      allergiesMedicalNotes: {
        value: "",
        error: false,
      },
      preferredTeammates: {
        value: "",
        error: false,
      },
    };

    commit("ADD_KID", newKid);
  },

  loadKid({ commit }, airtableID) {
    return new Promise((resolve, reject) => {
      base("Kids").find(airtableID, function(err, record) {
        if (err) {
          reject(err);
        }

        let birthDateString = record.get("Birth Date");
        let birthDate = "";

        if (birthDateString) {
          birthDate = new Intl.DateTimeFormat("en", {
            month: "numeric",
            day: "numeric",
            year: "2-digit",
          }).format(new Date(record.get("Birth Date")));
        }

        const updatedFields = {
          airtableID: record.getId(),
          name: {
            value: record.get("Name"),
            required: true,
            error: false,
          },
          birthDate: {
            value: birthDate,
            validation: "DATE",
            required: true,
            greaterThan: "10/01/2019",
            error: false,
          },
          grade: {
            value: record.get("Grade"),
            required: true,
            error: false,
          },
          preferPlayUp: {
            value: record.get("Prefer Play Up"),
            error: false,
          },
          soccerExperience: {
            value: record.get("Experience"),
            required: true,
            error: false,
          },
          gender: {
            value: record.get("Gender"),
            required: true,
            error: false,
          },
          guthrieSeasons: {
            value: record.get("Guthrie"),
            required: true,
            error: false,
          },
          school: {
            value: record.get("School"),
            required: true,
            error: false,
          },
          schoolOther: {
            value: record.get("School Other"),
            required: false,
            error: false,
          },
          shirtSize: {
            value: record.get("Shirt Size"),
            required: true,
            error: false,
          },
          avoidDay: {
            value: record.get("Avoid Day"),
            error: false,
          },
          allergiesMedicalNotes: {
            value: record.get("Allergies/Medical Notes"),
            error: false,
          },
          preferredTeammates: {
            value: record.get("Preferred Teammate(s)"),
            error: false,
          },
        };

        commit("ADD_KID", updatedFields);
        resolve(true);
      });
    });
  },

  deleteKid({ commit }, airtableID) { //eslint-disable-line
    base("Kids").destroy(airtableID, function(err) {
      if (err) {
        console.error(err);
        return;
      }
    });
  },

  validatePromoCode({ commit }, promoCode) { //eslint-disable-line
    return new Promise((resolve, reject) => {
      let searchFormula = `SEARCH(
            "${promoCode.toLowerCase()}",
            LOWER({Code})
          )`;

      let hasResults = false;
      let codeResults = {};

      base("Promo Codes")
        .select({
          view: "All Codes",
          filterByFormula: searchFormula,
        })
        .eachPage(
          function page(codes, fetchNextPage) {
            // This function (`page`) will get called for each page of records.
            codes.forEach(function(code) {
              if (code.get("Available") >= 1) {
                codeResults.id = code.getId();
                codeResults.type = code.get("Type");
                hasResults = true;
              }
            });

            // To fetch the next page of records, call `fetchNextPage`.
            // If there are more records, `page` will get called again.
            // If there are no more records, `done` will get called.
            fetchNextPage();
          },
          async function done(err) {
            if (err) {
              console.error(err);
              reject(err);
              return;
            }

            if (hasResults) {
              resolve(codeResults);
            } else {
              resolve(false);
            }
          }
        );
    });
  },
};

function validateField(field) {
  let errored = false;

  // if there's a validation, use that, otherwise check for blank field
  if (field.validation == "DATE") {
    const d = new Date(field.value);
    // validate date
    if (Object.prototype.toString.call(d) === "[object Date]") {
      // it is a date
      if (isNaN(d.getTime())) {
        // d.valueOf() could also work
        // date is not valid
        errored = true;
      }

      // has a "greaterThan" property
      if (field.greaterThan !== undefined) {
        if (d > new Date(field.greaterThan)) {
          // date must be older than latest date (kid must be older than that date)
          errored = true;
        }
      }
    } else {
      // not a date
      errored = true;
    }
  } else if (field.validation) {
    if (field.value) {
      const regex = new RegExp(field.validation);
      !regex.test(field.value) ? (errored = true) : null;
    } else {
      errored = true;
    }
  } else {
    if (field.required) {
      if (field.value) {
        field.value.length < 1 ? (errored = true) : null;
      } else {
        errored = true;
      }
    }
  }

  return errored;
}

function syncFields({ baseName, fields, airtableID }) {
  return new Promise((resolve, reject) => {
    if (airtableID) {
      // update airtable record
      base(baseName).update(
        [
          {
            id: airtableID,
            fields: fields,
          },
        ],
        (err, records) => {
          if (err) {
            reject(err);
          }

          resolve(records[0].getId());
        }
      );
    } else {
      // TODO: bug when creating new records when setting a registration status
      delete fields["Registration Status"];

      // create new airtable record
      base(baseName).create(
        [
          {
            fields: fields,
          },
        ],
        (err, records) => {
          if (err) {
            reject(err);
          }
          resolve(records[0].getId());
        }
      );
    }
  });
}
