import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [{
        path: '/',
        name: 'Home',
        component: Home
    },
    {
        path: '/register',
        component: () =>
            import ( /* webpackChunkName: "register" */ '../views/Register.vue'),
        children: [
            {
                path: '',
                name: 'Lookup',
                component: () =>
                    import ( /* webpackChunkName: "lookup" */ '../views/Lookup.vue')
            },
            {
                path: 'form',
                name: 'Form',
                component: () =>
                    import ( /* webpackChunkName: "form" */ '../views/Form.vue')
            },
            {
                path: 'payment-complete',
                name: 'PaymentComplete',
                component: () =>
                    import ( /* webpackChunkName: "paymentComplete" */ '../views/PaymentComplete.vue')
            },
            {
                path: 'lookup-payment',
                name: 'LookupPayment',
                component: () =>
                    import ( /* webpackChunkName: "lookupPayment" */ '../views/LookupPayment.vue')
            }
        ]
    },

]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

export default router