export const baseFields = {
    airtableID: null,
    householdLastName: {
        value: '',
        required: true,
        error: false
    },
    registrationStatus: {
        value: 'Unregistered',
        required: false,
        error: false
    },
    paymentStatus: {
        value: 'Unpaid',
        required: false,
        error: false
    },
    parent1Name: {
        value: '',
        error: false,
        required: true
    },
    parent2Name: {
        value: '',
        error: false
    },
    phoneNumber: {
        value: '',
        validation: "^[+]?[(]?[0-9]{3}[)]?[-\\s.]?[0-9]{3}[-\\s.]?[0-9]{4}$",
        required: true,
        error: false
    },
    email: {
        value: '',
        validation: "^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\\.[a-zA-Z0-9-]+)*$",
        required: true,
        error: false
    },
    address1: {
        value: '',
        required: true,
        error: false
    },
    address2: {
        value: '',
        error: false
    },
    addressCity: {
        value: '',
        required: true,
        error: false
    },
    addressState: {
        value: '',
        required: true,
        error: false
    },
    addressZip: {
        value: '',
        validation: "^\\d{5}(-\\d{4})?$",
        required: true,
        error: false
    },
    medicalCarrier: {
        value: '',
        error: false,
        required: true
    },
    medicalMemberID: {
        value: '',
        error: false,
        required: true
    },
    medicalGroupID: {
        value: '',
        error: false,
        required: true
    },
}

export const baseKids = [{
    airtableID: null,
    name: {
        value: 'Jill',
        required: true,
        error: false
    },
    birthDate: {
        value: '01/01/2005',
        validation: 'DATE',
        greaterThan: '10/01/2019',
        required: true,
        error: false
    },
    grade: {
        value: '1',
        required: true,
        error: false
    },
    preferPlayUp: {
        value: false,
        error: false
    },
    soccerExperience: {
        value: 'None',
        required: true,
        error: false
    },
    gender: {
        value: '',
        required: true,
        error: false
    },
    guthrieSeasons: {
        value: 'None',
        required: true,
        error: false
    },
    school: {
        value: 'Bob Jones Elementary',
        required: true,
        error: false
    },
    schoolOther: {
        value: '',
        error: false
    },
    shirtSize: {
        value: 'YXS',
        required: true,
        error: false
    },
    allergiesMedicalNotes: {
        value: '',
        error: false
    },
    preferredTeammates: {
        value: '',
        error: false
    }
}]